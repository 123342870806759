.dashboard-news {
  background: #ffffff;
  padding: 60px;
}

.dashboard-row {
  margin: 0px;
  padding: 0px;
}

.dashboard-col-middle {
  padding: 40px;
}

.dashboard-logo {
  height: 222px;
  width: 222px;
  margin-bottom: 30px;
}

.dashboard-container {
  background: #ffffff;
  height: 287px;
}

.dashboard-row-middle {
  height: 100%;
  display: flex;
  align-items: center;
}

.dashboard-card {
  text-align: center;
  margin-bottom: 15px;
}

#root {
  height: 100%;
}

.container-fluid {
  height: 100%;
}

.dashboard-row {
  height: 100%;
}
