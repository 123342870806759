@font-face {
  font-family: Roboto-Regular;
  src: url(../styles/font-family/Roboto-Regular.ttf);
}

@font-face {
  font-family: Roboto-Bold;
  src: url(../styles/font-family/Roboto-Bold.ttf);
}

@font-face {
  font-family: Roboto-Medium;
  src: url(../styles/font-family/Roboto-Medium.ttf);
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
}

body {
  font-family: Roboto-Regular, Arial, Helvetica, sans-serif;
  background: rgb(246, 246, 246);
  height: 100%;
  min-width: 320px;
  min-height: 480px;
}

math, mrow, mn, mo, mi {
  font-family: Roboto-Regular;
}

html {
  height: calc(100% - 90px);
}

.strong-text {
  font-weight: 900;
}

.normal-text {
  font-weight: 300;
}

@media only screen and (min-width: 768px) {
  body {
    font-size: 16px;
  }

  h1 {
    color: #373a3c;
    line-height: 200%;
    font-weight: 300;
    font-size: 3.052em;
    font-style: normal;
    font-family: Roboto-Regular, Arial, Helvetica, sans-serif;
  }

  h2 {
    color: #373a3c;
    line-height: 130%;
    font-weight: 500;
    font-size: 2.441em;
    font-style: normal;
    font-family: Roboto-Medium, Arial, Helvetica, sans-serif;
  }

  h3 {
    color: #373a3c;
    line-height: 200%;
    font-weight: 500;
    font-size: 1.953em;
    font-style: normal;
    font-family: Roboto-Medium, Arial, Helvetica, sans-serif;
  }

  h4 {
    color: #373a3c;
    line-height: 200%;
    font-weight: 700;
    font-size: 1.563em;
    font-style: normal;
    text-transform: uppercase;
    font-family: Roboto-Thin, Arial, Helvetica, sans-serif;
  }

  .large-body-text {
    font-size: 1.25em;
    font-weight: 500;
    font-family: Roboto-Medium, Arial, Helvetica, sans-serif;
  }

  .normal-body-text {
    font-size: 1em;
    font-weight: 300;
    font-family: Roboto-Regular, Arial, Helvetica, sans-serif;
  }

  .small-body-text {
    font-size: 0.875em;
    font-weight: 300;
    font-family: Roboto-Regular, Arial, Helvetica, sans-serif;
  }
}

@media only screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }


  h1 {
    color: #373a3c;
    line-height: 200%;
    font-weight: 300;
    font-size: 3.052em;
    font-style: normal;
    font-family: Roboto-Regular, Arial, Helvetica, sans-serif;
  }

  h2 {
    color: #373a3c;
    line-height: 130%;
    font-weight: 500;
    font-size: 2.441em;
    font-style: normal;
    font-family: Roboto-Medium, Arial, Helvetica, sans-serif;
  }

  h3 {
    color: #373a3c;
    line-height: 200%;
    font-weight: 500;
    font-size: 1.953em;
    font-style: normal;
    font-family: Roboto-Medium, Arial, Helvetica, sans-serif;
  }

  h4 {
    color: #373a3c;
    line-height: 200%;
    font-weight: 300;
    font-size: 1.563em;
    font-style: normal;
    text-transform: uppercase;
    font-family: Roboto-Medium, Arial, Helvetica, sans-serif;
  }

  .large-body-text {
    font-size: 1.25em;
    font-weight: 500;
    font-family: Roboto-Medium, Arial, Helvetica, sans-serif;
  }

  .normal-body-text {
    font-size: 1.33em;
    font-weight: 300;
    font-family: Roboto-Regular, Arial, Helvetica, sans-serif;
  }

  .small-body-text {
    font-size: 1.17em;
    font-weight: 300;
    font-family: Roboto-Regular, Arial, Helvetica, sans-serif;
  }
}

.primary-button {
  height: 37px;
  background: #fcc542;
  border-radius: 20px;
  border: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: #373a3c;
  align-self: center;
  margin: 10px 0px;
}

.secondary-button {
  height: 39px;
  background: #ffffff;
  border-radius: 20px;
  border: 2px solid #fcc542;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: #373a3c;
  align-self: center;
  margin: 10px 0px;
  position: relative;
}

.primary-button:focus {
  background: #fcc542;
  color: #373a3c;
  outline: none;
  box-shadow: none;
}

.primary-button:hover {
  background: #616d75;
  color: white;
  outline: none;
  box-shadow: none;
}

.secondary-button:hover {
  color: #373a3c;
  border: 2px solid #fcc542;
  background: #f7f7f7;
  outline: none;
  box-shadow: none;
}

.secondary-button:focus {
  outline: none;
  box-shadow: none;
}

.icon {
  margin-right: 2px;
  padding-bottom: 2px;
}

.new-task {
  text-transform: uppercase;
}

.pill-large {
  display: flex;
  flex-direction: row;
  padding: 3px 11px;
  margin-left: 3px;
  margin-right: 3px;
  display: inline;

  background: #fcc542;
  border-radius: 20px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.pill-normal {
  background: #d4dee5;
  display: flex;
  flex-direction: row;
  padding: 3px 5px;
  display: inline;
  margin-left: 3px;
  margin-right: 3px;

  border-radius: 3px;

  color: #373a3c;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.container-fluid {
  margin-top: 90px;
  padding: 0px;
}

.row {
  margin: 20px;
  padding: 30px;
}

.inner-row {
  margin: 0px;
  padding: 0px;
}

.inner-col {
  margin: 0px;
  padding: 0px;
}

blockquote {
  border-left: 6px solid #fcc542;
  padding-left: 25px;
}

a {
  font-weight: 500;
  color: #118ddf;
  font-style: normal;
}

.form-label {
  font-weight: 700;
}

.form-control {
  background: #ffffff;
  border: 2px solid #616d75;
  box-sizing: border-box;
  border-radius: 3px;
}

.form-control:focus {
  background: #ffffff;
  border: 2px solid #118ddf;
  box-sizing: border-box;
  border-radius: 3px;
  outline: none;
  box-shadow: none;
}

.is-invalid:focus {
  outline: none;
  box-shadow: none;
}

.custom-control-input {
  background: #fcc542;
}

.task-number-input {
  width: 50px;
  display: inline-block;
}

.task-number-label {
  display: block;
}

.task-number-icon {
  color: #118ddf;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.nav-link-with-no-decoration:active {
  text-decoration: none;
  color: #ffffff;
}

.nav-link-with-no-decoration:hover {
  text-decoration: none;
  color: #ffffff;
}

.nav-link-with-no-decoration:link {
  text-decoration: none;
  color: #ffffff;
}

.nav-link-with-no-decoration:visited {
  text-decoration: none;
  color: #ffffff;
}

.button-link-with-no-decoration:active {
  text-decoration: none;
  color: #373a3c;
}

.button-link-with-no-decoration:hover {
  text-decoration: none;
  color: #373a3c;
}

.button-link-with-no-decoration:link {
  text-decoration: none;
  color: #373a3c;
}

.button-link-with-no-decoration:visited {
  text-decoration: none;
  color: #373a3c;
}

.h3-title-link {
  font-size: 0.65em;
  vertical-align: middle;
}

.bottom-border {
  border-bottom: 2px solid #fcc542;
}

.block {
  display: block;
}

.link-button {
  color: #118ddf;
  outline: none;
  box-shadow: none;
}

.link-button:hover {
  color: #118ddf;
  text-decoration: underline;
  outline: none;
  box-shadow: none;
}

.link-button:active,
.link-button:focus {
  outline: none;
  box-shadow: none;
}

.link-without-redirection,
.link-without-redirection:active,
.link-without-redirection:focus {
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
  padding: 0px;
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.link-without-redirection:hover {
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
  padding: 0px;
  text-decoration: underline;
  outline: none;
  box-shadow: none;
}

.link-without-redirection-blue,
.link-without-redirection-blue:hover,
.link-without-redirection-blue:focus {
  color: #118ddf;
}

.link-without-redirection-red,
.link-without-redirection-red:hover,
.link-without-redirection-red:focus {
  color: #d63f2b;
}

.full-div {
  font-size: 1em;
  width: 100vw;
  height: 100vh;
  text-align: center;
}

.text-capitalize {
  text-transform: capitalize;
}

.exercise-border {
  border: 2px solid #d4dee5;
}

.no-hover {
    pointer-events: none;
}

.active-card-spinner {
  min-height: 180px;
}