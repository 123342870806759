.create-row {
    background-color: #ffffff;
    margin: 0px;
    border-bottom: 2px solid #fcc542;
}

.create-row-bottom {
    margin: 0px;
}

.create-col-middle {
    border-right: 2px solid #d4dee5;
}

.subject-and-search-row
{
    display: none;
}

.toolbar-button,
.toolbar-button:hover,
.toolbar-button:focus {
    border-radius: 3px 3px 0px 0px;
    text-decoration: none;
    outline: none;
    box-shadow: none;
    padding: 10px 20px 10px 20px;
}

.toolbar-button-active,
.toolbar-button-active:hover,
.toolbar-button-active:focus {
    background: #fcc542;
    color: #373a3c;
}

.form-row {
    margin: 0px;
    padding: 0px;
}

.form-col {
    padding: 0px !important;
}

.remove-exercise {
    color: red;
}

    .remove-exercise:hover {
        color: red;
    }

.icon-success {
    font-size: 10em !important;
    color: green;
}

.success-icon-col {
    text-align: right;
}

.success-middle-col {
    padding-top: 20px;
}

.block-checkbox {
    display: block !important;
}

.btn-available {
    color: #118ddf;
}

    .btn-available:hover {
        color: #118ddf;
    }

.settings-header {
    border-bottom: 1px solid black;
    padding-top: 50px;
    margin-bottom: 50px;
}

.inner-element {
    padding-left: 30px;
}

.blue-icon {
    color: #118ddf;
}

.btn-disabled,
.btn-disabled:hover,
.btn-disabled:focus {
    color: #89949a;
    cursor: context-menu !important;
}

.has-tabs {
    padding-bottom: 0px;
}

.toolbar-button-disabled,
.toolbar-button-disabled:hover,
.toolbar-button-disabled:focus {
    cursor: no-drop !important;
    background-color: #373A3C;
    color: #FFFFFF;
}
