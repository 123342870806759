svg {
    font-size: 12px;
}

.exercise-create-container {
    width: 100%;
    margin: 0px;
    padding: 10px 0px;
}

.exercise-create-row {
    margin: 0px;
    padding: 0px;
    display: flex;
}

.exercise-create-small {
    color: #616d75;
    font-size: 14px;
    padding: 5px;
    font-weight: normal;
}

.exercise-create-normal {
    padding-bottom: 0px;
    padding: 5px;
}

.exercise-create-col-right {
    text-align: right;
    font-weight: 500;
    font-size: 16px;
    display: none;
}

.exercise-create-card {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 2px solid #ffffff;
    min-width: 300px;
    min-height: 180px;
}

    .exercise-create-card:hover .exercise-create-col-right {
        box-sizing: border-box;
        display: inline;
    }

.exercise-create-card-body {
    height: 116px;
    background: #ffffff;
    border: 1px solid #d4dee5;
}

.exercise-create-quiz-card-body {
    height: 116px;
    background: #ffffff;
}

.exercise-create-badge {
    color: white;
    display: inline;
    align-items: center;
    padding: 5px 0px 0px 0px;
}

.multiple-choise-answer,
.multiple-choise-answer-view {
    background: #f6f6f6;
    color: #373a3c;
    font-size: 22px;
    border-radius: 7px;
    padding: 10px 25px 10px 25px;
    margin: 10px 20px 10px 0px;
    min-width: 120px;
    max-width: 280px;
}

    .multiple-choise-answer:hover {
        background: #d4dee5;
        cursor: pointer;
    }

.multiple-choise-answer-selected,
.multiple-choise-answer-view-selected {
    background: #373a3c;
    color: #f7f7f7;
    pointer-events: none;
}

.exercise-create-card-border {
    border: 2px solid #fcc542;
}

.exercise-card-error {
    padding-top: 60px;
    text-align: center;
    background-color: #ff665120;
}

.exercise-card-error p {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.exercise-row-solutionstep {
    border-bottom: 2px solid #f6f6f6;
}

.exercise-row-solutionstep-last {
    border-bottom: 0px;
}


.exercise-solutionstep-index {
    color: #373a3c;
    padding: 5px;
}

.outer-icon {
    position: absolute;
    top: 25px;
    left: -30px;
}

.outer-icon-up {
    position: absolute;
    top: 25px;
    left: -30px;
    color: #118ddf;
    transform: rotate(-180deg);
}

.outer-icon-down {
    position: absolute;
    top: 60px;
    left: -30px;
    color: #118ddf;
}

.student-input-field {
    width: 100px;
    display: inline-block;
    margin-left: 15px;
}

.exercise-create-settings {
    padding: 16px;
}

.exercise-question {
    font-size: 22px;
}
