.list1-card {
  height: 50px;
  border: 1px solid #d4dee5;
  border-radius: 0px;
}

.list1-card:hover {
  height: 50px;
  border: 1px solid #d4dee5;
  border-radius: 0px;
  background: #fcc542;
}

.list-pill {
  margin-left: 10px;
  background: #d4dee5;
  color: #89949a;
}

.list-container {
  height: 50px;
  margin: 0px;
  padding: 0px;
  background: #ffffff;
}

.list-row {
  height: 50px;
  margin: 0px;
  padding: 0px;
}

.list-col {
  height: 50px;
  margin: 0px;
  padding: 0px;
  display: table;
  margin: auto;
  text-align: center;
}

.list1-card-body {
  padding: 0px;
  text-transform: none;
}

.list1-container {
  margin: 0px;
  padding: 0px;
  background: #ffffff;
}

.list1-container:hover {
  margin: 0px;
  padding: 0px;
  background: #fcc542;
}

.list1-row {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
}

.list1-col-left {
  text-align: left;
}

.list1-col-right {
  text-align: right;
}

.MuiFormControlLabel-root {
  margin: 0px;
  padding: 0px;
}

.content-group-exercise-card-body > .list3-card {
  margin: 10px 0px 10px 25px;
}
.list3-card {
  margin: 10px 0px 10px 0px;
}

.list3-container {
  margin: 0px;
  padding: 0px;
  background: #ffffff;
  border: 2px solid #ffffff;
}

.list3-container:hover {
  border: 2px solid #fcc542;
}

.list3-container:hover .list3-col-right {
  display: inline;
}

.list3-row {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
}

.list3-small {
  color: #616d75;
  font-size: 14px;
  padding: 5px;
  font-weight: normal;
}

.list3-normal {
  font-weight: 500;
  font-size: 20px;
  padding: 5px;
}

.list3-col-right {
  text-align: right;
  font-weight: 500;
  font-size: 16px;
  display: none;
}

.list1-select-subcategory-label
{
    user-select: none
}
