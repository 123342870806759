.content-group-topic-container {
  height: 70px;
  margin: 0px;
  padding: 0px;
  background: #ffffff;
  text-align: left;
}

.content-group-topic-row {
  margin: 0px;
  padding: 0px;
  display: flex;
}

.content-group-topic-small {
  color: #373a3c;
  font-size: 14px;
  padding: 5px;
  font-weight: normal;
}

.content-group-topic-normal {
  color: #373a3c;
  font-weight: 500;
  font-size: 20px;
  padding: 5px;
  text-transform: capitalize;
}

.content-group-topic-col-right {
  text-align: right;
  font-weight: 500;
  font-size: 16px;
  display: none;
}

.content-group-topic-card:hover {
  border: 2px solid #fcc542;
  box-sizing: border-box;
}

.content-group-topic-accordion {
  border: 2px solid #ffffff;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.content-group-topic-accordion-open {
  border: 2px solid #fcc542;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.content-group-topic-accordion:hover {
  border: 2px solid #fcc542;
  border-radius: 5px;
}

.content-group-topic-button {
  padding: 0px;
  margin: 0px;
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.content-group-topic-button {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.content-group-topic-button:hover {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.content-group-topic-button:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.content-group-topic-open {
  background: #fcc542;
}
.content-group-topic-collapse {
  background: #ffffff;
  border-radius: 2px;
}

.content-group-topic-icon {
  color: #373a3c;
}

.content-group-topic-col-icon {
  display: flex;
  align-items: center;
}

.content-group-topic-card-body {
  padding: 0px;
}

.content-group-exercise-container {
  margin: 0px;
  padding: 0px;
  background: #ffffff;
  text-align: left;
}

.content-group-exercise-row {
  margin: 0px;
  padding: 0px;
  display: flex;
}

.content-group-exercise-small {
  color: #373a3c;
  font-size: 14px;
  padding: 5px;
  font-weight: normal;
}

.content-group-exercise-normal {
  color: #373a3c;
  font-weight: 500;
  font-size: 20px;
  padding: 5px;
}

.content-group-exercise-col-right {
  text-align: right;
  font-weight: 500;
  font-size: 16px;
  display: none;
}

.content-group-exercise-card:hover {
  border: 2px solid #fcc542;
  box-sizing: border-box;
}

.content-group-exercise-accordion {
  margin-top: 15px;
}

.content-group-exercise-button {
  padding: 0px;
  margin: 0px;
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.content-group-exercise-button {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.content-group-exercise-button:hover {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.content-group-exercise-button:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.content-group-exercise-open {
  background: #fcc542;
  border: 0px;
}
.content-group-exercise-collapse {
  background: rgb(246, 246, 246);
  border-radius: 2px;
}

.content-group-exercise-icon {
  color: #373a3c;
}

.content-group-exercise-col-icon {
  display: flex;
  align-items: center;
}

.content-group-exercise-card-body {
  background: rgb(246, 246, 246);
  padding-right: 0px;
}

.card {
  border: 0px;
}

.content-group-exercise-card-body {
  padding: 0px;
}
