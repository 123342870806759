@font-face {
    font-family: Roboto-Thin;
    src: url(../styles/font-family/Roboto-Thin.ttf);
}

@font-face {
    font-family: Etelka-Medium-Pro;
    src: url(../styles/font-family/Etelka-Medium-Pro.ttf);
}

.logo {
    width: 90px;
    height: 90px;
    padding: 5px;
}

.navbar {
    height: 90px;
    background-color: #118ddf;
}

.navbar-button-right {
    padding-right: 0px;
}

.not-button-icon {
    color: #fcc542;
}

.nav-link {
    margin-left: 5px;
    margin-right: 5px;
}

.nav-link,
.divider {
    font-weight: 500;
    font-size: 16px;
    color: #ffffff !important;
}

.with-background {
    background: #118;
}

.navbar-button {
    height: 90px;
    background: #fcc542;
    border: 0px;
    border-radius: 0px;
    color: black;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    padding-left: 30px;
    padding-right: 30px;
}

    .navbar-button:hover {
        background: #616d75;
        color: white;
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }

    .navbar-button:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }

.navbar-brand-text {
    color: white;
    font-weight: 500;
    font-size: 25px;
    text-transform: uppercase;
    margin-left: 25px;
    font-family: Roboto-Thin;
}

.navbar-input {
    border: 3px solid #373a3c;
    border-radius: 3px;
    width: 447px;
    height: 56px;
}

.input-group-text {
    color: #ffffff;
    background: #373a3c;
    border: 0px;
    border-radius: 0px;
}

.navbar-input-field {
    border: 0px;
    font-weight: 500;
    color: #000;
    height: 50px;
}

.navbar-student {
    background: #fcc542;
}

.navbar-student-button,
.navbar-student-button:hover {
    background: #118ddf;
    color: #ffffff;
}

    .navbar-student-button:hover {
        background: #616d75;
        color: white;
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }

    .navbar-student-button:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }

.nav-container {
    height: 90px;
    background-color: #118ddf;
}

.nav-container-row {
    margin: 0px;
    padding: 0px;
}

@media only screen and (min-width: 769px) {
    .navbar-title-top {
        font-weight: 300;
        font-size: 18px;
        color: #ffffff;
        text-transform: uppercase;
        font-family: Etelka-Medium-Pro;
    }

    .navbar-title-bottom {
        font-weight: 300;
        font-size: 24px;
        color: #ffffff;
        text-transform: uppercase;
        font-family: Etelka-Medium-Pro;
    }
}

@media only screen and (max-width: 768px) {
    .navbar-title-top {
        font-weight: 300;
        font-size: 0.6em;
        color: #ffffff;
        text-transform: uppercase;
        font-family: Etelka-Medium-Pro;
    }

    .navbar-title-bottom {
        font-weight: 300;
        font-size: 0.6em;
        color: #ffffff;
        text-transform: uppercase;
        font-family: Etelka-Medium-Pro;
    }

    .navbar-brand {
        padding: 0px;
        margin: 0px;
    }

        .navbar-brand > img {
            width: 40px;
            height: auto;
        }

    .nav-container {
        padding: 0px;
        margin: 0px;
    }
}

.notification-info {
    border-left:8px solid #373a3c !important
}

.notification-info,.notification-info .timer {
    background-color:#118ddf !important
}

.notification-info .timer-filler {
    background-color:#fff
}

.notification-info .notification-close {
    background-color:#118ddf !important
}